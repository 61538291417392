import React from "react";
import PropTypes from "prop-types";
import "./Sponsor.scss";

const Sponsor = (props) => {
  const { graphics } = props;

  // Ako nema grafike za footer, ne prikazuj komponentu
  if (!graphics?.footerText || !graphics?.footerLogoUrl) {
    return null;
  }

  return (
    <div className="sponsor-wrapper">
      {graphics.footerText}
      <img src={graphics.footerLogoUrl} alt="sponsor logo" />
    </div>
  );
};

Sponsor.propTypes = {
  graphics: PropTypes.object,
};

export default Sponsor;
