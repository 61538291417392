import React from "react";
import PropTypes from "prop-types";
import { GameTitleContainer, GameLogo } from "./GameTitle.styled";

const GameTitle = (props) => {
  return (
    <GameTitleContainer>
      <GameLogo
        src={props?.graphics.primaryLogoUrl || "/logo.jpg"}
        alt="logo"
      />
      <div style={{ fontSize: "26px" }}>
        {props?.graphics.primaryText || "Cryptic DJ"}
      </div>
      {props?.graphics.secondaryLogoUrl && (
        <GameLogo
          src={props.graphics.secondaryLogoUrl}
          alt="secondary logo"
        />
      )}
    </GameTitleContainer>
  );
};

GameTitle.propTypes = {
  children: PropTypes.node,
  graphics: PropTypes.object,
};

export default GameTitle;
