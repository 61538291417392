import React, { useEffect, useRef, useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  GameDescription,
  GuessesContainer,
  PlayContentContainer,
  HelpAboutContainer,
} from "./PlayContent.styled";
import PlayerLives from "../../components/PlayerLives/PlayerLives";
import GameTitle from "../../components/GameTitle/GameTitle";
import GuessSongField from "../../components/GuessSongField/GuessSongField";
import { useDispatch, useSelector } from "react-redux";
import { selectGame } from "../../store/selectors/gameSelectors";
import { fetchGame } from "../../store/actions/game/gameActions";
import LinkTextField from "../../components/TextField/LinkTextField/LinkTextField";
import { useTranslation } from "react-i18next";
import DetailsIcon from "../../components/DetailsIcon/DetailsIcon";
import DetailsCard from "../../components/DetailsCard/DetailsCard";
import PlayerController2 from "../../components/PlayerController/PlayerController2";
import FooterHistory from "../../components/Footer/FooterHistory";
import Sponsor from "../../components/Sponsor/Sponsor";
import { useParams } from "react-router-dom";

import gameStates from "../../constants/gameStates";

const PlayContent = () => {
  const { t } = useTranslation();
  const game = useSelector(selectGame); // Povlačenje niza kvizova iz Redux store-a
  const dispatch = useDispatch();

  const [currentGameIndex, setCurrentGameIndex] = useState(0); // Indeks trenutnog kviza
  const currentGame = game && game.length > 0 ? game[currentGameIndex] : null; // Trenutni kviz

  const [hints, setHints] = useState({});
  const [graphics, setGraphics] = useState({});

  const songRefs = useRef([null, null, null, null]);
  const [lives, setLives] = useState(30);
  const [scoreTimers, setScoreTimers] = useState([0, 0, 0, 0, 0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const timerStatus = useRef(false);
  const [solvedFields, setSolvedFields] = useState(0);
  const [cluesRevealed, setCluesRevealed] = useState(false);
  const [titleOrArtistCluesRevealed, setTitleOrArtistCluesRevealed] =
    useState(false);
  const [toggleClues, setToggleClues] = useState(false);
  const [toggleTitleOrArtistClues, setToggleTitleOrArtistClues] =
    useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [activePlayer, setActivePlayer] = useState([true, false, false, false]); // Samo jedan igrač može biti aktivan
  const [cardType, setCardType] = useState("");
  const [currentSongUrl, setCurrentSongUrl] = useState("");
  const [decimalTime, setDecimalTime] = useState(0);
  const [decimalInterval, setDecimalInterval] = useState(-1);
  const PlayerController2Ref = useRef();
  const [isShowTimer, setIsShowTimer] = useState(false);
  const [isLoadedSongs, setIsLoadedSongs] = useState([
    false,
    false,
    false,
    false,
  ]);

  const [answersTimeOutStatus, setAnswersTimeOutStatus] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const [isGaveUpFields, setIsGaveUpFields] = useState([
    false,
    false,
    false,
    false,
  ]);

  const [gameState, setGameState] = useState(gameStates.INITIAL);

  const { directory } = useParams();

  // useEffect za preuzimanje igre
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const isPlayed = localStorage.getItem("played");
    console.log("Loading game data...");

    if (!isPlayed) {
      setCardType("help");
      localStorage.setItem("played", JSON.stringify(true));
    }

    const payload = directory
      ? { directoryName: directory, date: today }
      : { date: today };

    dispatch(fetchGame(payload));
  }, [directory, dispatch]);

  // useEffect za postavljanje hints i graphics
  useEffect(() => {
    if (
      JSON.stringify(hints) === "{}" &&
      currentGame &&
      currentGame.songs &&
      currentGame.songs.length > 0
    ) {
      const hints_data = {
        things_or_phrases: currentGame.songs[0].hints.find(
          (hint) => hint.name === "Things or Phrases"
        ),
        title_or_artist: currentGame.songs.map(
          (song) =>
            song.hints.find((hint) => hint.name === "Title or Artist")?.hint
        ),
        highlight_words: currentGame.songs.map(
          (song) =>
            song.hints.find((hint) => hint.name === "Highlight Word")?.hint
        ),
      };
      setHints(hints_data);
      console.log("GAME OBJECT:", currentGame);

      console.log("HINTS DATA OBJECT:", hints_data);

      console.log("HINTS OBJECT:", hints);
    }
    if (currentGame && currentGame.graphics) {
      const {
        footerLogoUrl = "",
        footerText = "",
        masterHead = "",
        primaryLogoUrl = "",
        secondaryLogoUrl = "",
        primaryText = "",
      } = currentGame.graphics;
      setGraphics({
        footerLogoUrl,
        footerText,
        masterHead,
        primaryLogoUrl,
        secondaryLogoUrl,
        primaryText,
      });
      setIsShowTimer(currentGame.shouldShowTimer);
    }
  }, [currentGame, hints]);

  useEffect(() => {
    handleClearInterval();
  }, [answersTimeOutStatus]);

  const loseLife = () => {
    // Poziva se iz child kao prop
    if (lives > 0) setLives((prevLives) => prevLives - 1);
  };

  const updateScoreTimers = (time, index) => {
    // Ažurira scoreTimer sa vremenom pesme
    setScoreTimers((prevState) =>
      prevState.map((el, idx) => {
        if (idx === index) {
          if (el <= time) {
            return time;
          } else {
            return el;
          }
        } else {
          return el;
        }
      })
    );
  };

  const giveUpScoreTimers = (index) => {
    setScoreTimers((prevState) => {
      console.log(
        prevState.map((el, idx) => {
          if (idx === index) {
            return (el += 20);
          } else {
            return el;
          }
        })
      );
      return prevState.map((el, idx) => {
        if (idx === index) {
          return (el += 20);
        } else {
          return el;
        }
      });
    });
  };

  const scoreTimer = useMemo(() => {
    let score = Math.floor(scoreTimers.reduce((sum, el) => (sum += el)));
    return score;
  }, [scoreTimers]);

  const updateTimerStatus = () => {
    if (timerStatus.current) return;
    timerStatus.current = true;
  };

  const solveField = () => {
    // Poziva se iz child kao prop
    setSolvedFields((prevSolvedFields) => prevSolvedFields + 1);
  };

  const revealClues = () => {
    // Poziva se iz child kao prop
    if (!cluesRevealed && !gameOver) {
      setLives((prevLives) => prevLives - 3);
      setCluesRevealed(true);
      setScoreTimers((prevState) =>
        prevState.map((el, idx) => {
          if (idx === prevState.length - 1) {
            if (el === 0) {
              return (el += 10);
            }
          } else {
            return el;
          }
        })
      );
    }
    setToggleClues((prevState) => !prevState);
  };

  const revealTitleOrArtistClues = () => {
    if (!titleOrArtistCluesRevealed && !gameOver) {
      setLives((prevLives) => prevLives - 3);
      setTitleOrArtistCluesRevealed(true);
    }
  };

  const revealClueHint = () => {
    if (solvedFields < 3) return;
    setCluesRevealed(true);
    setToggleClues(true);
  };

  const revealTitleOrArtistClueHint = () => {
    if (solvedFields < 3) return;
    setTitleOrArtistCluesRevealed(true);
    setToggleTitleOrArtistClues(true);
  };

  const revealHints = (addingTime) => {
    if (!gameOver && addingTime) {
      const newScoreTimers = scoreTimers.map((item) => item);
      newScoreTimers.push(Number(addingTime));
      setScoreTimers(newScoreTimers);
    }
  };

  const playSong = (indexOfSong) => {
    // Poziva se iz child kao prop...samo jedna može da se pusti
    songRefs?.current?.forEach((singleSongRef, index) => {
      if (index !== indexOfSong) {
        singleSongRef.pauseSong();
        singleSongRef.song.pause();
      }
    });
  };

  const togglePlayers = (index) => {
    // Skrivanje svih plejera osim onog koji je kliknut
    setActivePlayer(
      activePlayer.map((el, idx) => {
        if (idx !== index) {
          return (el = false);
        } else {
          return (el = true);
        }
      })
    );
    playSong(index);
  };

  const handleSetDecimalInterval = () => {
    if (decimalInterval !== null && decimalInterval > 0) {
      return;
    }
    setDecimalInterval(
      setInterval(() => {
        setDecimalTime((prevState) => {
          return (prevState + 1) % 10;
        });
      }, 100)
    );
  };

  const handleClearInterval = () => {
    if (decimalInterval) {
      clearInterval(decimalInterval);
      setDecimalInterval(null);
    }
  };

  const setDecimalTimeToZero = () => {
    setDecimalTime(0);
  };

  const handleTimeUpController = () => {
    handleClearInterval();
    setDecimalTimeToZero();
  };

  useEffect(() => {
    if (gameOver) {
      setCluesRevealed(true);
      setToggleClues(true);
    }
  }, [gameOver]);

  const handleSetGameState = (state) => {
    setGameState(state);
  };

  const pauseMusic = () => {
    if (PlayerController2Ref.current) {
      PlayerController2Ref.current.onPauseChildren();
    }
  };

  const handleNextGame = () => {
    if (currentGameIndex < game.length - 1) {
      setCurrentGameIndex(currentGameIndex + 1);
      resetGameState();
    }
  };

  const handlePrevGame = () => {
    if (currentGameIndex > 0) {
      setCurrentGameIndex(currentGameIndex - 1);
      // Resetovanje stanja igre
      resetGameState();
    }
  };

  const resetGameState = () => {
    setHints({});
    setGraphics({});
    setLives(30);
    setScoreTimers([0, 0, 0, 0, 0]);
    setSolvedFields(0);
    setCluesRevealed(false);
    setTitleOrArtistCluesRevealed(false);
    setToggleClues(false);
    setToggleTitleOrArtistClues(false);
    setGameOver(false);
    setIsPlaying(false);
    setActivePlayer([true, false, false, false]);
    setCurrentSongUrl("");
    setDecimalTime(0);
    setDecimalInterval(-1);
    setIsShowTimer(false);
    setIsLoadedSongs([false, false, false, false]);
    setAnswersTimeOutStatus([false, false, false, false, false]);
    setIsGaveUpFields([false, false, false, false]);
    setGameState(gameStates.INITIAL);
  };

  return (
    <PlayContentContainer>
      <GameTitle graphics={graphics} />
      <PlayerLives
        noOfLives={lives}
        isPlaying={isPlaying}
        gameOver={gameOver}
        scoreTimer={scoreTimer}
        timerStatus={timerStatus.current}
        decimalTime={decimalTime}
        gameState={gameState}
        isShowTimer={isShowTimer}
      />
      <GameDescription>
        {/* {t("game.description")} */}
        {graphics.masterHead || t("game.description")}
      </GameDescription>
      <GuessesContainer onClick={updateTimerStatus}>
        {JSON.stringify(hints) !== "{}" &&
          currentGame?.songs?.map((singleSong, index) => {
            return (
              <GuessSongField
                cluesRevealed={toggleClues}
                titleOrArtistCluesRevealed={toggleTitleOrArtistClues}
                loseLife={loseLife}
                playSong={playSong}
                setIsPlaying={setIsPlaying}
                isPlaying={isPlaying}
                disabled={gameOver || isGaveUpFields[index]}
                ref={(elRef) => (songRefs.current[index] = elRef)}
                solveField={solveField}
                key={singleSong?.id}
                songUrl={singleSong?.url}
                songIndex={index}
                hint={hints.highlight_words[index]}
                titleOrArtistHint={hints.title_or_artist[index]}
                songName={singleSong?.name}
                displayName={singleSong?.displayName}
                artist={singleSong?.artist}
                songId={singleSong?.id}
                activePlayer={activePlayer}
                togglePlayers={togglePlayers}
                scoreTimers={scoreTimers[index]}
                updateScoreTimers={updateScoreTimers}
                giveUpScoreTimers={giveUpScoreTimers}
                handleSetDecimalInterval={handleSetDecimalInterval}
                handleClearInterval={handleClearInterval}
                setDecimalTimeToZero={setDecimalTimeToZero}
                setCurrentSongUrl={setCurrentSongUrl}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                pauseMusic={pauseMusic}
                isLoaded={isLoadedSongs[index]}
                answersTimeOutStatus={answersTimeOutStatus}
                setAnswersTimeOutStatus={setAnswersTimeOutStatus}
                isGaveUpFields={isGaveUpFields}
              />
            );
          })}
        {!gameOver && currentGame?.songs?.length > 0 && solvedFields < 4 && (
          <PlayerController2
            disabled={gameOver}
            ref={PlayerController2Ref}
            currentSongUrl={currentSongUrl}
            handleSetDecimalInterval={handleSetDecimalInterval}
            handleClearInterval={handleClearInterval}
            setDecimalTimeToZero={setDecimalTimeToZero}
            scoreTimers={scoreTimers}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            updateScoreTimers={updateScoreTimers}
            giveUpScoreTimers={giveUpScoreTimers}
            setIsLoadedSongs={setIsLoadedSongs}
            isLoadedSongs={isLoadedSongs}
            answersTimeOutStatus={answersTimeOutStatus}
            setAnswersTimeOutStatus={setAnswersTimeOutStatus}
            handleTimeUpController={handleTimeUpController}
            setIsPlaying={setIsPlaying}
            isGaveUpFields={isGaveUpFields}
            setIsGaveUpFields={setIsGaveUpFields}
          />
        )}
      </GuessesContainer>
      <LinkTextField
        answers={currentGame?.answers}
        loseLife={loseLife}
        totalLives={lives}
        questionId={currentGame?.id}
        gameOver={() => setGameOver(true)}
        revealClues={revealClues}
        cluesRevealed={cluesRevealed}
        titleOrArtistCluesRevealed={titleOrArtistCluesRevealed}
        revealTitleOrArtistClues={revealTitleOrArtistClues}
        solvedFields={solvedFields}
        scoreTimer={scoreTimer}
        handleSetGameState={handleSetGameState}
        decimalTime={decimalTime}
        disabled={gameOver}
        revealHints={revealHints}
        hints={hints}
        revealClueHint={revealClueHint}
        revealTitleOrArtistClueHint={revealTitleOrArtistClueHint}
      />

      {(gameState === gameStates.WON || gameState === gameStates.LOST) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            gap: "10px",
          }}
        >
          {currentGameIndex < game.length - 1 && (
            <button
              onClick={handleNextGame}
              style={{
                color: "black",
                backgroundColor: "white",
                border: "none",
                padding: "10px",
                borderRadius: "8px",
                fontWeight: "bold",
              }}
            >
              Next Quiz
            </button>
          )}
        </div>
      )}

      {cardType === "help" && (
        <DetailsCard cardType="help" setCardType={setCardType} />
      )}
      {cardType === "submit" && (
        <DetailsCard cardType="submit" setCardType={setCardType} />
      )}
      {cardType === "about" && (
        <DetailsCard cardType="about" setCardType={setCardType} />
      )}
      {cardType === "score" && (
        <DetailsCard
          cardType="score"
          // score={finalScore}
          gameState={gameState}
        />
      )}
      <div
        style={{
          borderTop: "2px solid #fff",
          paddingTop: "20px",
          marginTop: "26px",
        }}
      >
        {(gameState === gameStates.WON || gameState === gameStates.LOST) && (
          <FooterHistory />
        )}
        <HelpAboutContainer>
          <DetailsIcon
            cardType="help"
            setCardType={setCardType}
            // blockingGame={blockingGame}
          />
          <DetailsIcon
            cardType="submit"
            setCardType={setCardType}
            // blockingGame={blockingGame}
          />
          <DetailsIcon
            cardType="about"
            setCardType={setCardType}
            // blockingGame={blockingGame}
          />
        </HelpAboutContainer>
      </div>
      <Sponsor graphics={graphics} />
    </PlayContentContainer>
  );
};

PlayContent.propTypes = {
  children: PropTypes.node,
};

export default PlayContent;
